// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["uKCCh1bka"];

const variantClassNames = {uKCCh1bka: "framer-v-bs9x0p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "uKCCh1bka", image: yLsOBSLt9, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "uKCCh1bka", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-9PMZS", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-bs9x0p", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"uKCCh1bka"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fit", intrinsicHeight: 1060, intrinsicWidth: 860, pixelHeight: 1060, pixelWidth: 860, ...toResponsiveImage_194x2gw(yLsOBSLt9)}} className={"framer-4u67xs"} data-framer-name={"Test"} layoutDependency={layoutDependency} layoutId={"c67c5eHke"} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9PMZS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9PMZS * { box-sizing: border-box; }", ".framer-9PMZS .framer-phxc2j { display: block; }", ".framer-9PMZS .framer-bs9x0p { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 773px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1080px; }", ".framer-9PMZS .framer-4u67xs { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 773px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9PMZS .framer-bs9x0p, .framer-9PMZS .framer-4u67xs { gap: 0px; } .framer-9PMZS .framer-bs9x0p > *, .framer-9PMZS .framer-4u67xs > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-9PMZS .framer-bs9x0p > :first-child, .framer-9PMZS .framer-4u67xs > :first-child { margin-left: 0px; } .framer-9PMZS .framer-bs9x0p > :last-child, .framer-9PMZS .framer-4u67xs > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 773
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"yLsOBSLt9":"image"}
 */
const FramerwOuN95ES9: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerwOuN95ES9;

FramerwOuN95ES9.displayName = "test";

FramerwOuN95ES9.defaultProps = {height: 773, width: 1080};

addPropertyControls(FramerwOuN95ES9, {yLsOBSLt9: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerwOuN95ES9, [])